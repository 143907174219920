import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import SmallSectionHeader from "./SmallSectionHeader"
import { theme } from "../styles/global-styles"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import SyntaxHighlighter from "react-syntax-highlighter"

const ArticleWrapper = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
`

const Article = styled.article`
  width: 100%;
  margin: 4em 4rem 0 4rem;
  max-width: ${theme.widths.mainContainer};
  @media only screen and (max-width: 959px) {
    max-width: ${theme.widths.smContainer};
  }
  @media only screen and (max-width: 740px) {
    margin: 4em 4rem 0 4rem;
  }
  @media only screen and (max-width: 599px) {
    margin: 1.5em 1.5rem 0 1.5rem;
  }
`
const ArticleText = styled.div`
  /* width: 100%; */
  padding: 2rem 0;
`

const ArticleDate = styled.h6``

const ArticleContainer = props => {
  const data = useStaticQuery(graphql`
    query {
      article: file(relativePath: { eq: "The_Bash_Stash_JMaddox.jpg" }) {
        id
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <ArticleWrapper>
      <Article>
        {/* <Img fluid={data.article.childImageSharp.fluid} /> */}
        <SmallSectionHeader
          color={theme.colors.accentTwo}
          pointsForSVG={false}
          headerTitle={props.publishDate}
          margBottom={"2rem"}
        />

        <ArticleText>
          <div>{props.richText}</div>
        </ArticleText>
      </Article>
    </ArticleWrapper>
  )
}

export default ArticleContainer

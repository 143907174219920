import React from "react"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import get from "lodash/get"
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Img from "gatsby-image"
import Layout from "../components/Layout"
import { theme } from "../styles/global-styles"
import styled from "@emotion/styled"

import FullWidthHero from "../components/FullWidthHero"
import MainNav from "../components/MainNav"
import SEO from "../components/Seo"
import Footer from "../components/Footer"
import ArticleContainer from "../components/ArticleContainer"

const arr = []

const GlobalBold = styled.p`
  font-weight: bold;
  display: inline;
`

class BlogPostTemplate extends React.Component {
  render() {
    const post = get(this.props, "data.contentfulBlogPost")
    const siteTitle = get(this.props, "data.site.siteMetadata.title")

    const jsonTopics = post.bodyTwo.json
    const options = {
      renderMark: {
        [MARKS.BOLD]: text => <GlobalBold>{text}</GlobalBold>,
        [MARKS.ITALIC]: text => <em>{text}</em>,
        [MARKS.UNDERLINE]: text => <u>{text}</u>,
        [MARKS.CODE]: text => {
          arr.push(text)
          return
        },
      },
      renderNode: {
        [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
        [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
        [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
        [BLOCKS.OL_LIST]: (node, children) => <ol type="1">{children}</ol>,
        [BLOCKS.HR]: node => <hr></hr>,
        [BLOCKS.QUOTE]: (node, children) => <blockquote>{children}</blockquote>,
        [BLOCKS.HEADING_1]: (node, children) => <h1>{children}</h1>,
        [BLOCKS.HEADING_2]: (node, children) => <h2>{children}</h2>,
        [BLOCKS.HEADING_3]: (node, children) => <h3>{children}</h3>,
        [BLOCKS.HEADING_4]: (node, children) => <h4>{children}</h4>,
        [BLOCKS.HEADING_5]: (node, children) => <h5>{children}</h5>,
        [BLOCKS.HEADING_6]: (node, children) => <h6>{children}</h6>,
        [INLINES.ENTRY_HYPERLINK]: (node, children) => <a>{children}</a>,
        [INLINES.HYPERLINK]: (node, children) => (
          <a href={`${node.data.uri}`}>{children}</a>
        ),
      },
    }

    return (
      <Layout location={this.props.location}>
        <SEO title="Need to add an seo title in the content model" />
        <MainNav
          logoAndMenuColor={theme.colors.light}
          bgColor={theme.colors.dark}
          hamburgerOn={true}
          stickyOn={false}
        />
        <FullWidthHero
          backgroundColor={theme.colors.dark}
          headerColor={theme.colors.light}
          headerText={post.title}
        ></FullWidthHero>
        <ArticleContainer
          richText={documentToReactComponents(jsonTopics, options)}
          publishDate={post.publishDate}
          testArr={arr}
        />
        <Footer />
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      publishDate(formatString: "MMMM Do, YYYY")
      heroImage {
        fluid(maxWidth: 1180, background: "rgb:000000") {
          ...GatsbyContentfulFluid_tracedSVG
        }
      }
      bodyTwo {
        json
      }
    }
  }
`
